import React, { useState } from "react";
import './App.css';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

function App() {
  const { width, height } = useWindowSize()


  const buttonTexts = [
    "Where is the dev?", "Maybe here?", "No, not there!", "Try the other side.",
    "You're getting warmer!", "Cold, very cold...", "Turn around!", "Look up.",
    "Now, look down.", "You're close!", "Not so fast, look around.",
    "Almost there...", "Just a little more effort.", "You're persistent!",
    "Look to the left.", "Now to the right.", "Almost got it.",
    "Just a bit more...", "Almost found it..."
  ];

  const initialButtons = buttonTexts.map(text => ({ text, active: false }));
  const [buttons, setButtons] = useState(initialButtons);

  const activateNextButton = (index) => {
    const newButtons = buttons.map((button, i) => {
      if (i === index) return { ...button, active: false };
      if (i === index + 1) return { ...button, active: true };
      return button;
    });
    setButtons(newButtons);
  };
  // Vérifie si aucun bouton n'est actif
  const noButtonActive = buttons.every(button => !button.active);
 // Condition pour vérifier si l'utilisateur a trouvé le dev
 const hasFoundDev = buttons[buttons.length - 1].active;
  return (
    <div className="App-header h-screen">
      {buttons.map((button, index) => (
        button.active && (
          <button 
            key={index}
            className="animate-pulse absolute" 
            style={{ 
              top: `${Math.random() * 80}vh`, 
              left: `${Math.random() * 80}vw`
            }}
            onClick={() => activateNextButton(index)}>
            {button.text}
          </button>
        )
      ))}
      {!hasFoundDev ? 
      <img src={"/confused-john-travolta.gif"} className="App-logo" alt="logo" />
      :
      <img src={"/found.gif"} className="App-logo" alt="logo" />
    }
      {noButtonActive && (
        <button className="animate-pulse bg-black text-white font-bold py-2 px-4 rounded my-8" onClick={() => activateNextButton(0)}>
        help us to found dev
        </button>
        )}
      {hasFoundDev && (
        <>
 <Confetti
      width={width}
      height={height}
      />
      <div className="animate-bounce my-5 ">
           <div className="rainbow-text my-3">
        Congratulations! You've found the dev!
      </div>
      </div>
        <div className="mx-auto w-full flex flex-col md:flex-row justify-around bg-white">
          <a  href="https://dexscreener.com/solana/hbrgwsqcjslgdxkfbb3kxuzfj4yzabmfamhyjpt5ei27" target="_blank" rel="noopener noreferrer"
             className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">
            Dexscreener
          </a>
          <a  href="https://birdeye.so/token/4ZUiH2cAQD7e3DSpmdCMVkrJLSdKT1kKYGMeFqkJxaAL?chain=solana" target="_blank" rel="noopener noreferrer"
             className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Birdeye
          </a>
          <a  href="https://raydium.io/swap/?inputCurrency=4ZUiH2cAQD7e3DSpmdCMVkrJLSdKT1kKYGMeFqkJxaAL&outputCurrency=sol&inputAmount=3939245.71191&outputAmount=1.629602&fixed=in" target="_blank" rel="noopener noreferrer"
             className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Buy on Raydium
          </a>
        </div>
               </>
    )}
    </div>
  );
}

export default App;